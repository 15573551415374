import React, { useState, memo, useEffect } from "react";
import {
    SttContainer,
    SttLoading,
    SttAlerta,
    SttGrid,
    SttButton,
    SttPaper,
    SttBox,
    SttInput,
    SttHeading,
    SttTranslate
} from '@stt-componentes/core';
import HttpStatus from 'http-status-codes';
import {
    Formik,
    FastField,
} from 'formik';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getBearerHeaders } from '../requests';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

const testeNovaSenhaConfirmacaoIguais = (parent) => {
    if (!parent.novaSenha || !parent.confirmacaoSenha) {
        return true;
    }
    return parent.novaSenha === parent.confirmacaoSenha;
}

const validationSchema = (strings) => {
    return yup.object().shape({
        'senhaAtual': yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio),
        'novaSenha': yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio)
            .test('senhasIguais', strings.novaSenhaConfirmacaoSenhaIgual, function () {
                return testeNovaSenhaConfirmacaoIguais(this.parent);
            }),
        'confirmacaoSenha': yup
            .string()
            .nullable()
            .trim()
            .required(strings.campoObrigatorio)
            .test('senhasIguais', strings.novaSenhaConfirmacaoSenhaIgual, function () {
                return testeNovaSenhaConfirmacaoIguais(this.parent);
            }),
    })
}

const useStyles = makeStyles(theme => ({
    grid: {
        marginBottom: theme.spacing(5)
    },
    formColumn: {
        backgroundColor: theme.palette.background.default,
        border: '2px solid',
        borderColor: '#D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        width: '80%',
        marginTop: theme.spacing(5)
    },
    expansionPanel: {
        width: '100%'
    },
    box: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    alert: {
        margin: theme.spacing(2)
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    heading: {
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
}));


const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const MeusDadosFormSenha = ({ strings, user }) => {
    const classes = useStyles();

    const [initialValues, setInitialValues] = useState(null);

    //Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: () => {
            setMostrarAlerta(false);
        }
    }]);
    const [onClose, setOnClose] = useState(() => setMostrarAlerta(false));

    useEffect(() => {
        if (user.id) {
            setInitialValues({
                confirmacaoSenha: '',
                novaSenha: '',
                senhaAtual: ''
            });
        }
    }, [user.id]);

    const enviarForm = (dados, setSubmitting, resetForm) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        setMostrarAlerta(false);
        setSubmitting(true);
        axios.post(`${oauthBaseUrl}/interno/update-user-password`, dados, { headers: getBearerHeaders() })
            .then(function (response) {
                const { data } = response;
                setSubmitting(false);
                setTituloAlerta(strings.sucesso);
                setMensagemAlerta(data?.message || strings.operacaoSucesso);
                setTipoAlerta('success');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                            resetForm({ values: initialValues })
                        }
                    }
                ]);
                setOnClose(() => () => {
                    setMostrarAlerta(false);
                    resetForm({ values: initialValues })
                });
                setMostrarAlerta(true);
            })
            .catch(function (err) {
                const { response } = err;

                setSubmitting(false);
                let mensagem = strings.erro;
                if (response) {
                    const { data } = response;
                    mensagem = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        if (data.errors) {
                            data.errors.forEach(error => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                        }
                        if (arrMensagem.length > 0) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }
                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => setMostrarAlerta(false)
                    }
                ]);
                setOnClose(() => () => setMostrarAlerta(false));
                setMostrarAlerta(true);
            });
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <SttPaper className={classes.formColumn}>
                    <SttBox className={classes.box} letterSpacing={1}>
                        <SttHeading variant="h2" color="primary" className={classes.heading}>
                            {strings.alteracaoSenha}
                        </SttHeading>
                        {
                            initialValues &&
                            <Formik
                                initialValues={initialValues}
                                validateOnChange={false}
                                validationSchema={validationSchema(strings)}
                                onSubmit={(dados, { setSubmitting, resetForm }) => {
                                    setSubmitting(false);
                                    setTituloAlerta(strings.atencao);
                                    setMensagemAlerta(strings.confirmarAlteracao);
                                    setTipoAlerta('alert');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.sim,
                                            onClick: () => enviarForm(dados, setSubmitting, resetForm)
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => setMostrarAlerta(false)
                                        }
                                    ]);
                                    setOnClose(() => () => setMostrarAlerta(false));
                                    setMostrarAlerta(true);
                                }}
                            >
                                {
                                    ({
                                        isSubmitting,
                                        values,
                                        handleSubmit,
                                        setFieldValue
                                    }) => (
                                        <>
                                            <SttContainer>
                                                <form onSubmit={handleSubmit} noValidate>
                                                    <SttGrid container spacing={1} justifyContent="center">
                                                        <SttGrid item xs={12} md={8} lg={5}>
                                                            <SttGrid container spacing={1} justifyContent="center">
                                                                <SttGrid item xs={12} >
                                                                    <FastField name='senhaAtual'>
                                                                        {({
                                                                            field,
                                                                            meta
                                                                        }) => (
                                                                            <SttInput
                                                                                inputProps={{
                                                                                    maxLength: 20
                                                                                }}
                                                                                {...field}
                                                                                type="password"
                                                                                autoComplete="current-password"
                                                                                required={true}
                                                                                onInput={e => e.target.value = ('' + e.target.value).replace(' ', '')}
                                                                                label={strings.senhaAtual}
                                                                                error={meta.touched && meta.error ? true : false}
                                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            />
                                                                        )}
                                                                    </FastField>
                                                                </SttGrid>
                                                            </SttGrid>
                                                            <SttGrid container spacing={1} justifyContent="center">
                                                                <SttGrid item xs={12}>
                                                                    <FastField name='novaSenha'>
                                                                        {({
                                                                            field,
                                                                            meta
                                                                        }) => (
                                                                            <SttInput
                                                                                inputProps={{
                                                                                    maxLength: 20
                                                                                }}
                                                                                {...field}
                                                                                type="password"
                                                                                autoComplete="new-password"
                                                                                required={true}
                                                                                onInput={e => e.target.value = ('' + e.target.value).replace(' ', '')}
                                                                                label={strings.novaSenha}
                                                                                error={meta.touched && meta.error ? true : false}
                                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            />
                                                                        )}
                                                                    </FastField>
                                                                </SttGrid>
                                                            </SttGrid>
                                                            <SttGrid container spacing={1} justifyContent="center">
                                                                <SttGrid item xs={12}>
                                                                    <FastField name='confirmacaoSenha'>
                                                                        {({
                                                                            field,
                                                                            meta
                                                                        }) => (
                                                                            <SttInput
                                                                                inputProps={{
                                                                                    maxLength: 20
                                                                                }}
                                                                                {...field}
                                                                                type="password"
                                                                                autoComplete="new-password"
                                                                                required={true}
                                                                                onInput={e => e.target.value = ('' + e.target.value).replace(' ', '')}
                                                                                label={strings.confirmacaoSenha}
                                                                                error={meta.touched && meta.error ? true : false}
                                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            />
                                                                        )}
                                                                    </FastField>
                                                                </SttGrid>
                                                            </SttGrid>
                                                            <div className={classes.buttonWrapper}>
                                                                <SttButton
                                                                    type="submit"
                                                                    variant="contained"
                                                                    nomarginleft="true"
                                                                    className={classes.button}
                                                                    color="primary"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {strings.salvar}
                                                                </SttButton>
                                                            </div>
                                                        </SttGrid>
                                                    </SttGrid>
                                                </form>
                                                <SttLoading
                                                    open={isSubmitting}
                                                    text={strings.salvandoMensagemEspera}
                                                />
                                            </SttContainer>

                                        </>
                                    )
                                }
                            </Formik>
                        }
                    </SttBox>
                </SttPaper>
            </div>
            <Alerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onClose}
            />
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(SttTranslate('MeusDadosFormSenha')(withRouter(MeusDadosFormSenha)));