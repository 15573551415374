import React, { useEffect } from 'react';
import { Autenticacao, Cache } from '@stt-componentes/cache';

/**
 * Modos de autenticação
 * 0 - Autenticação com refresh token.
 * 1 - Autenticação sem refresh token. 
 * 2 - Autenticação sem refresh token + restaurar tempo de acesso com focus.
 * 3 - Autenticação sem refresh token + restaurar tempo de acesso sem focus.
 */

/**
 * Página que será renderizado num iframe (SttCacheManager).
 * Sincroniza itens do localStorage entre os módulos.
 * 
 * Regras de negósico:
 * 1. Verifica se o usuário está logado.
 *    - Se estiver logado, repassa essa informações de sincronização para o iframe.
 *    - Caso não esteja logado, emitir a acão de redirecionamento.
 * 2. Verificar se o localStorage foi modificado.
 *    - Caso sim, emitir sincronização de tokens.
 * 3. Enviar mensagens de um módulo ao outro.
 */

/**
 * Cache Manager com refresh token.
 */
const CacheManager = ({ loginFn }) => {
    console.log('Inicializar CacheManager!');
    const config = global.gConfig;

    useEffect(() => {
        loginFn(config).then((response) => {
            // Alterar localmente
            const userInfo = response.data;
            Cache.setUserInfo(userInfo);

            const message = {
                "acao": "SINCRONIZAR_LOCAL_STORAGE",
                "data": {
                    "stt-authorization": Cache.getAccessToken(),
                    "refresh_token": Cache.getRefreshToken(),
                    "user_info": JSON.stringify(userInfo)
                },
            };

            /** o event 'storage' só é captado por outras guias. E preciso enviar a mensagem para a guia atual manualmente. */
            window.parent.postMessage(message, "*");
            Cache.setEventBus(message);
        }).catch((e) => {
            const message = {
                "acao": "LOGOUT"
            };
            /** o event 'storage' só é captado por outras guias. E preciso enviar a mensagem para a guia atual manualmente. */
            window.parent.postMessage(message, "*");
            Cache.setEventBus(message);
        });
    }, []);

    useEffect(() => {
        // Quando renovar o token a partir de uma request do módulo. 
        const messageListener = (e) => {
            const { acao, data } = e.data;
            if (acao === "SINCRONIZAR_LOCAL_STORAGE") {
                const accessToken = data["stt-authorization"];
                const refreshToken = data["refresh_token"];
                const userInfoSerial = data["user_info"];
                if (accessToken && refreshToken) {
                    const message = {
                        "acao": "SINCRONIZAR_LOCAL_STORAGE",
                        "data": {
                            "stt-authorization": accessToken,
                            "refresh_token": refreshToken,
                            "user_info": userInfoSerial
                        },
                    };
                    // Modificar o módulo login
                    Cache.setAccessToken(accessToken);
                    Cache.setRefreshToken(refreshToken);
                    Cache.setUserInfoSerial(userInfoSerial);

                    // Modificar os outros módulos conectados
                    Cache.setEventBus(message);
                }

            }
            // if (e.origin.includes("localhost")) {
            // }
        };
        window.addEventListener('message', messageListener, false);

        // Enviar mensagem recebida para os outros módulos
        const storageListener = (event) => {
            if (event.storageArea != localStorage) return;

            if (event.key === 'eventbus') {
                const eventBus = Cache.getEventBus();
                window.parent.postMessage(eventBus, "*");
            }
        };
        window.addEventListener('storage', storageListener);


        return () => {
            window.removeEventListener('message', messageListener);
            window.removeEventListener('storage', storageListener);
        };
    }, [])

    return (
        <></>
    );
};

/** CacheManager com refresh token */
const CacheManagerCRT = ({ loginFn, ...props }) => <CacheManager {...props} loginFn={Autenticacao.login} />;

/** CacheManager sem refresh token */
const CacheManagerSRT = ({ loginFn, ...props }) => <CacheManager {...props} loginFn={Autenticacao.loginSemRefreshToken} />;

export { CacheManagerCRT, CacheManagerSRT };
