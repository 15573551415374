import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import httpStatus from 'http-status-codes';
import { useQuery } from '../hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttHeading,
    SttInput,
    SttButton,
    SttAlerta,
    SttLoading,
    SttGrid,
    SttPaper
} from '@stt-componentes/core';
import translate from './../translate';
import request from '../requests';
import { Cache } from '@stt-componentes/cache';
import TermoUso from './termo-uso';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../azure-ad/authConfig";
import Util from "../util";
import { ORIGEM_AUTENTICACAO_EXTERNA } from '../common/AppConstants';
const { ServerError, ClientAuthError, BrowserAuthError } = require('@azure/msal-browser');
import { SttIcon } from '@stt-componentes/icons';

/**
 * Definição de estilos específicos da página
 */
const useStyles = makeStyles((theme) => ({
    inputSenha: {
        marginTop: theme.spacing(3)
    },
    heading: {
        marginBottom: theme.spacing(4),
        color: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#ffffff';
            }
        },
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    link: {
        textDecoration: 'none',
        color: props => {
            switch (props.basename) {
                case 'ufpa':
                    return '#3a6381';
                default:
                    return 'inherit';
            }
        }
    },
    esqueceuSenha: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
    },
    novoCadastro: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
        textTransform: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'uppercase';
            }
        },
        fontFamily: props => {
            switch (props.basename) {
                case 'ufpa':
                    return 'Sans-serif';
            }
        },
        color: props => {
            switch (props.basename) {
                case 'ebserh':
                    return '#143768';
            }
        },
    },
    botaoEntrar: {
        width: theme.spacing(16)
    },
    iconeAutenticacaoExterna: {
        fontSize: theme.spacing(5),
        cursor: 'pointer'
    },
    tituloAutenticacaoExterna: {
        marginTop: '10px',
        color: '#bcbcbc'
    },
    paper: {
        padding: theme.spacing(0),
        background: theme.palette.background.default
    },
    versaoSTT: {
        color: '#bcbcbc'
    }
}));

/**
 * Componente de login, com formulário e opção de recuperação de senha
 *
 * @param {Object} props
 */
const LoginForm = ({ strings, usarLoginPadrao }) => {
    // Variáveis de estado
    const [loginFail, setLoginFail] = useState(false);
    const [loginFailTitle, setLoginFailTitle] = useState('');
    const [loginFailMessage, setLoginFailMessage] = useState('');
    const [openCarregando, setOpenCarregando] = useState(true);
    const [modalTermoUso, setModalTermoUso] = useState();
    const history = useHistory();
    const { instance } = useMsal();

    const handleClose = () => {
        setLoginFail(false);
    };

    const [alertOptions] = useState([
        {
            title: strings.fechar,
            onClick: handleClose
        }
    ]);

    // Alerta geral
    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.atencao);
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    const query = useQuery();
    const redirectUri = query.get('redirect_uri');
    const state = query.get('state');
    const responseType = query.get('response_type');
    const stateVariable = state ? `&state=${state}` : '';
    const redirectBaseUrl = redirectUri || global.gConfig.default_redirect_url;

    // Classes css
    var propsCss = { basename: global.gConfig.basename };
    const classes = useStyles(propsCss);

    // Esquema de validação do formulário
    const schema = Yup.object().shape({
        username: Yup.string().required(strings.usuarioObrigatorio),
        password: Yup.string().required(strings.senhaObrigatoria)
    });

    const confirmarVincularContaMicrosoft = ({ azureOid, email, idUsuario, loginUsuario, accessToken, refreshToken, redirectUrl }) => {
        setTipoAlerta('alert');
        setTituloAlerta(strings.atencao);
        setMensagemAlerta(strings.confirmarVincularAutenticacaoExterna(ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD, email));
        setOpcoesAlerta([{
            title: strings.sim, onClick: () => {
                setOpenCarregando(true);
                const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
                const payload = {
                    origem: ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD,
                    identificadorAutenticacao: azureOid,
                    idUsuario: idUsuario,
                    login: loginUsuario,
                    email: email
                };

                axios.post(`${oauthBaseUrl}/auth/vincular-autenticacao-externa`, payload, { timeout: 15000 })
                    .then(function (response) {
                        Cache.setAccessToken(accessToken);
                        Cache.setRefreshToken(refreshToken);
                        window.location.assign(redirectUrl);
                    })
                    .catch(function (error) {
                        console.log(error);

                        let msg = strings.erroGenerico;

                        const { response } = error;
                        if (response?.status < httpStatus.INTERNAL_SERVER_ERROR) {
                            msg = response.data.message;
                        }

                        setTipoAlerta('error');
                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(msg);
                        setMostrarAlerta(true);
                        setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
                    }).finally(() => {
                        setOpenCarregando(false);
                    });
            }
        }, { title: strings.nao, onClick: () => setMostrarAlerta(false) }]);
        setMostrarAlerta(true);
    }

    // Submete os usuário e senha para autenticação
    const login = (values, { setSubmitting }) => {
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;
        setOpenCarregando(true);

        axios.post(`${oauthBaseUrl}/auth/login`, values, { timeout: 15000 })
            .then(function (response) {
                setOpenCarregando(false);
                const { data } = response;
                const accessToken = data.access_token;
                const refreshToken = data.refresh_token;

                // Redireciona o usuário
                let redirectUrl = `${redirectBaseUrl}?token=${accessToken}${stateVariable}`;
                if (responseType === 'code') {
                    redirectUrl = `${redirectBaseUrl}&code=${accessToken}`;
                }

                if (refreshToken) {
                    redirectUrl += `&refresh_token=${refreshToken}`;
                }

                // Verificar se a autenticação externa é obrigatória
                const autenticacaoAzure = global.gConfig.autenticacao_externa.origem.filter(origem => origem.identificador === ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD)[0];
                if (data.request_azure_external_authentication &&
                    global.gConfig.autenticacao_externa.ativo &&
                    autenticacaoAzure?.ativo &&
                    autenticacaoAzure?.obrigatoria) {
                    setTipoAlerta('alert');
                    setTituloAlerta(strings.atencao);
                    setMensagemAlerta(strings.mensagemNecessidadeAutenticacaoAzure);
                    setOnCloseAlerta(() => () => {
                        setMostrarAlerta(false);
                    })
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                recuperarCredenciaisAzureAd()
                                    .then((props) => {
                                        if (!props) {
                                            return;
                                        }
                                        //Verificar se o CPF da credencial é igual ao do usuário
                                        const cpfAzure = ('00000000000' + props.cpf.replace(/[\. ,:-]+/g, "")).slice(-11);
                                        if (!data.cpf || (('00000000000' + data.cpf.replace(/[\. ,:-]+/g, "")).slice(-11) !== cpfAzure)) {
                                            setTituloAlerta(strings.atencao);
                                            setMostrarAlerta(true);
                                            setMensagemAlerta(strings.azureCpfIncompativelCadastro);
                                            setOpcoesAlerta([{ title: strings.ok, onClick: () => setMostrarAlerta(false) }]);
                                            return;
                                        }
                                        const { azureOid, email } = props;
                                        // Vincular id da azure a este usuário
                                        setMostrarAlerta(false);
                                        confirmarVincularContaMicrosoft({
                                            azureOid: azureOid,
                                            email: email,
                                            idUsuario: data.id_usuario,
                                            loginUsuario: values.username,
                                            accessToken: accessToken,
                                            refreshToken: refreshToken,
                                            redirectUrl: redirectUrl
                                        })
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                            }
                        }
                    ]);
                    setTituloAlerta(strings.atencao);
                    setMostrarAlerta(true);
                    return;
                }

                // Modal não deve ser exibido na instância da EBSERH.
                if (data.request_terms_of_use && global.gConfig.config_id !== 'ebserh') {
                    setModalTermoUso({
                        accessToken,
                        refreshToken,
                        redirectUrl
                    });
                    return;
                }

                // Armazena os tokens no localStorage
                Cache.setAccessToken(accessToken);
                Cache.setRefreshToken(refreshToken);
                window.location.assign(redirectUrl);
            })
            .catch(function (error) {
                setOpenCarregando(false);
                console.log(error);

                let msg = strings.erroGenerico;

                const { response } = error;
                if (response?.status < httpStatus.INTERNAL_SERVER_ERROR) {
                    msg = response.data.message;
                }

                setLoginFailTitle(strings.erro);
                setLoginFailMessage(msg);
                setLoginFail(!loginFail);
                Cache.clear();
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    useEffect(() => {
        // Se já existe token, e esse Token é válido. Redireciona usuário.
        // Caso contrário, apenas remover do localStorage
        const accessToken = Cache.getAccessToken();
        const refreshToken = Cache.getRefreshToken();
        if (accessToken) {
            setOpenCarregando(true);
            request(global.gConfig)
                .me(accessToken)
                .then((response) => {
                    // Para usuário não precisar limpar o cache no Safari
                    Cache.setAccessToken(accessToken);
                    Cache.setRefreshToken(refreshToken);

                    let redirectUrl = `${redirectBaseUrl}?token=${accessToken}${stateVariable}`;
                    if (responseType === 'code') {
                        redirectUrl = `${redirectBaseUrl}&code=${accessToken}`;
                    }

                    if (refreshToken) {
                        redirectUrl += `&refresh_token=${refreshToken}`;
                    }
                    window.location.assign(redirectUrl);
                })
                .catch((error) => {
                    setOpenCarregando(false);
                    const { response } = error;
                    const msg = response.data.message;

                    // eslint-disable-next-line no-console
                    console.warn(`Remover token do localStorage: ${msg}`);
                    Cache.clear();
                });
        } else {
            setOpenCarregando(false);
        }
    }, []);

    const autenticarUsuarioAzureAd = async () => {
        const props = await recuperarCredenciaisAzureAd();
        if (!props) {
            return;
        }

        const { azureOid, cpf, email } = props;
        const oauthBaseUrl = global.gConfig.url_base_api_oauth2;

        if (!azureOid) {
            return;
        }

        setOpenCarregando(true);
        const payload = {
            origem: ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD,
            identificadorAutenticacao: azureOid,
            cpf: cpf,
            email: email
        };

        axios.post(`${oauthBaseUrl}/auth/login-autenticacao-externa`, payload, { timeout: 15000 })
            .then(function (response) {
                setOpenCarregando(false);
                const { data } = response;
                const accessToken = data.access_token;
                const refreshToken = data.refresh_token;

                // Redireciona o usuário
                let redirectUrl = `${redirectBaseUrl}?token=${accessToken}${stateVariable}`;
                if (responseType === 'code') {
                    redirectUrl = `${redirectBaseUrl}&code=${accessToken}`;
                }

                if (refreshToken) {
                    redirectUrl += `&refresh_token=${refreshToken}`;
                }

                // Modal não deve ser exibido na instância da EBSERH.
                if (data.request_terms_of_use && global.gConfig.config_id !== 'ebserh') {
                    setModalTermoUso({
                        accessToken,
                        refreshToken,
                        redirectUrl
                    });
                    return;
                }

                // Armazena os tokens no localStorage
                Cache.setAccessToken(accessToken);
                Cache.setRefreshToken(refreshToken);
                window.location.assign(redirectUrl);
            })
            .catch(function (error) {
                setOpenCarregando(false);
                console.log(error);

                let msg = strings.erroGenerico;

                const { response } = error;
                if (response?.status < httpStatus.INTERNAL_SERVER_ERROR) {
                    msg = response.data.message;
                }

                setLoginFailTitle(strings.erro);
                setLoginFailMessage(msg);
                setLoginFail(!loginFail);
                Cache.clear();
            });
    }

    const recuperarCredenciaisAzureAd = async () => {
        try {

            const credenciaisAzureAd = await instance.loginPopup(loginRequest);

            //Recuperar CPF do usuário
            const cpf = await Util.recuperarCpfAzure(credenciaisAzureAd.idTokenClaims.oid, credenciaisAzureAd.accessToken);

            return {
                azureOid: credenciaisAzureAd.idTokenClaims.oid,
                email: credenciaisAzureAd.idTokenClaims.preferred_username || credenciaisAzureAd.idTokenClaims.email,
                cpf: cpf,
                accessToken: credenciaisAzureAd.accessToken
            };
        } catch (error) {
            if (error instanceof ServerError || error instanceof ClientAuthError) {
                setMensagemAlerta(strings.erroGeralAutenticacaoExterna);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                })
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setTituloAlerta(strings.erro);
                setMostrarAlerta(true);
            } else if (error instanceof BrowserAuthError && error.errorCode === "interaction_in_progress") {
                setMensagemAlerta(strings.erroAutenticacaoExternaJaAberta);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                })
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setTituloAlerta(strings.erro);
                setMostrarAlerta(true);
            }
        }
    }

    const handleLogarAutenticacaoExterna = (identificador) => {
        switch (identificador) {
            case ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD:
                autenticarUsuarioAzureAd();
                break;
        }
    }

    // Cria o formulário de autenticação
    return (
        <>
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={schema}
                onSubmit={login}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        noValidate
                        className={classes.form}
                    >

                        <SttGrid container direction='column' alignItems='center'>
                            {
                                (global.gConfig.basename === 'ebserh' || global.gConfig.config_id === 'ebserh') && !usarLoginPadrao ?
                                    <>

                                        <SttGrid item sm={12} xs={12} style={{ marginTop: 30 }}>
                                            <SttPaper className={classes.paper}>
                                                <img src={'https://api-stt.ebserh.gov.br/public/imagens/logostt.png'} flex={1} height={80}></img>
                                            </SttPaper>
                                        </SttGrid>


                                        <SttGrid item sm={12} xs={12} style={{ marginTop: 30 }}>
                                            <SttButton
                                                color="externo"
                                                nomarginleft={1}
                                                type="button"
                                                size="large"
                                                variant="contained"
                                                disabled={isSubmitting}
                                                className={classes.botaoEntrar}
                                                onClick={() => handleLogarAutenticacaoExterna(ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD)}
                                            >
                                                {strings.entrar}
                                            </SttButton>
                                        </SttGrid>

                                        {
                                            global.gConfig.exibir_link_auto_cadastro &&
                                            <SttGrid item sm={12} xs={12} style={{ marginTop: 4 }}>
                                                <SttHeading
                                                    variant="h5"
                                                    color="primary"
                                                    className={classes.novoCadastro}
                                                    onClick={() => {
                                                        window.location.replace(global.gConfig.url_base_administrativo_auto_cadastro);
                                                    }}
                                                >
                                                    {strings.novoCadastro}
                                                </SttHeading>
                                            </SttGrid>
                                        }

                                        <SttGrid item sm={12} xs={12} style={{ marginTop: 80 }}>
                                            <SttHeading
                                                variant="h5"
                                                color="primary"
                                                className={classes.versaoSTT}>
                                                STT: v2
                                            </SttHeading>
                                        </SttGrid>
                                    </>
                                    :

                                    <>
                                        <SttGrid item sm={12} xs={12}>
                                            <SttHeading
                                                variant="h2"
                                                color="primary"
                                                className={classes.heading}
                                            >
                                                {strings.acessoSistema}
                                            </SttHeading>
                                        </SttGrid>
                                        <SttGrid item sm={12} xs={12}>
                                            <SttInput
                                                label={strings.usuario}
                                                name="username"
                                                value={values.username}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required
                                                error={!!(touched.username && errors.username)}
                                                helperText={errors.username}
                                                autoFocus
                                            />
                                        </SttGrid>

                                        <SttGrid item sm={12} xs={12}>
                                            <SttInput
                                                label={strings.senha}
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required
                                                error={!!(touched.password && errors.password)}
                                                className={classes.inputSenha}
                                                helperText={touched.password ? errors.password : ''}
                                            />
                                        </SttGrid>

                                        <SttGrid item sm={12} xs={12} style={{ marginTop: 4 }}>
                                            <SttHeading
                                                variant="h5"
                                                color="primary"
                                                className={classes.esqueceuSenha}
                                                onClick={() => {
                                                    history.replace('/esqueceu-senha');
                                                }}
                                            >
                                                {strings.esqueceuSenha}
                                            </SttHeading>
                                        </SttGrid>

                                        {
                                            global.gConfig.exibir_link_auto_cadastro &&
                                            <SttGrid item sm={12} xs={12} style={{ marginTop: 4 }}>
                                                <SttHeading
                                                    variant="h5"
                                                    color="primary"
                                                    className={classes.novoCadastro}
                                                    onClick={() => {
                                                        window.location.replace(global.gConfig.url_base_administrativo_auto_cadastro);
                                                    }}
                                                >
                                                    {strings.novoCadastro}
                                                </SttHeading>
                                            </SttGrid>
                                        }

                                        <SttGrid item sm={12} xs={12} style={{ marginTop: 4 }}>
                                            <SttButton
                                                color="primary"
                                                nomarginleft={1}
                                                type="submit"
                                                variant="contained"
                                                disabled={isSubmitting}
                                            >
                                                {strings.entrarNoSistema}
                                            </SttButton>
                                        </SttGrid>
                                    </>
                            }

                        </SttGrid>
                    </form>
                )}
            </Formik >
            <SttAlerta
                open={loginFail}
                title={loginFailTitle}
                message={loginFailMessage}
                type="error"
                options={alertOptions}
                onClose={handleClose}
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
            <SttLoading
                open={openCarregando}
                text={strings.carregando}
            />
            <TermoUso {...modalTermoUso}
                setModalTermoUso={setModalTermoUso}
            />

        </>
    );
};

export default translate('LoginForm')(LoginForm);