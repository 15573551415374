import React from 'react';
import {
    SttAdministrativo,
    SttAnalise,
    SttApac,
    SttEletrocardiografia,
    SttEletroencefalografia,
    SttEspirometria,
    SttEstomatologia,
    SttExames,
    SttMensagens,
    SttPolissonografia,
    SttTeleconsultorias,
    SttTeledermatologia,
    SttTeleeducacao,
    SttTfd,
    SttTfdPassagens,
    SttAlofono,
    SttConsultorioVirtual,
    SttHelpdesk,
    SttAgendaIcon,
} from '@stt-componentes/icons';
import { loginRequest, graphConfig } from "./azure-ad/authConfig";
import { retornaMsalInstance } from "./index";
import { ORIGEM_AUTENTICACAO_EXTERNA } from './common/AppConstants';
import axios from 'axios';

const retornarIconeModulo = (modulo, menuModulos) => {
    const transformClass = menuModulos ? 'scale(1.15)' : 'scale(1.25)';

    switch (modulo) {
        case 'ADMINISTRATIVO':
            return <SttAdministrativo style={{ transform: transformClass }} />

        case 'ANALISE':
            return <SttAnalise style={{ transform: transformClass }} />

        case 'APAC':
            return <SttApac style={{ transform: transformClass }} />

        case 'ELETROCARDIOGRAFIA':
            return <SttEletrocardiografia style={{ transform: transformClass }} />

        case 'EEG':
            return <SttEletroencefalografia style={{ transform: transformClass }} />

        case 'ESPIROMETRIA':
            return <SttEspirometria style={{ transform: transformClass }} />

        case 'ESTOMATO':
            return <SttEstomatologia style={{ transform: transformClass }} />

        case 'EXAMES':
            return <SttExames style={{ transform: transformClass }} />

        case 'MENSAGENS':
            return <SttMensagens style={{ transform: transformClass }} />

        case 'POLISSONOGRAFIA':
            return <SttPolissonografia style={{ transform: transformClass }} />

        case 'TELECONSULTORIAS':
            return <SttTeleconsultorias style={{ transform: transformClass }} />

        case 'TELEDERMATO':
            return <SttTeledermatologia style={{ transform: transformClass }} />

        case 'TELEEDUCACAO':
            return <SttTeleeducacao style={{ transform: transformClass }} />

        case 'TFD':
            return <SttTfd style={{ transform: transformClass }} />

        case 'TFDPASSAGENS':
            return <SttTfdPassagens style={{ transform: transformClass }} />

        case 'ALOFONO':
            return <SttAlofono style={{ transform: transformClass }} />

        case 'TELEATENDIMENTO':
        case 'CONECTA':
            return <SttConsultorioVirtual style={{ transform: transformClass }} />

        case 'HELPDESK':
            return <SttHelpdesk style={{ transform: transformClass }} />

        default:
            return <SttAgendaIcon style={{ transform: transformClass }} />

    }
}

const callMsGraph = async (accessToken) => {
    if (!accessToken) {
        const account = retornaMsalInstance().getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await retornaMsalInstance().acquireTokenSilent({
            ...loginRequest,
            account: account
        });
        accessToken = response.accessToken;
    }

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig().graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

const recuperarCpfAzure = async (oid, accessToken) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };

    const url = `${graphConfig().graphUsersEndpoint}${oid}?$select=employeeId`;

    try {
        const response = await axios.get(url, { headers });
        return response.data.employeeId;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const recuperarOidPorEmail = async (listaEmails, accessToken) => {
    const headers = {
        Authorization: `Bearer ${accessToken}`
    };

    let inserts = [];
    let erros = [];
    for (const le of listaEmails) {
        const url = `${graphConfig().graphUsersEndpoint}${le.email}`;

        try {
            const response = await axios.get(url, { headers });
            const resposta = response.data;

            inserts.push(`
                INSERT INTO public.usuario_autenticacao_externa(
	id_usuario, identificador_autenticacao, origem, email)
	VALUES (${le.id}, '${resposta.id}', 'AZURE_AD', '${le.email}');`.trim())

        } catch (error) {
            console.error(error);
            erros.push(le)
        }
    }

    const blob = new Blob([inserts], { type: 'text/plain' });
    
    // Cria um link temporário para o download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'inserts';
    
    // Anexa o link ao DOM, clica nele e remove-o
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    const bloberr = new Blob([erros], { type: 'text/plain' });
    
    // Cria um link temporário para o download
    const linkerr = document.createElement('a');
    linkerr.href = window.URL.createObjectURL(bloberr);
    linkerr.download = 'errors';
    
    // Anexa o link ao DOM, clica nele e remove-o
    document.body.appendChild(linkerr);
    linkerr.click();
    document.body.removeChild(linkerr);

}

const retornarIconeAutenticacaoExterna = (origem) => {
    switch (origem) {
        case ORIGEM_AUTENTICACAO_EXTERNA.AZURE_AD:
            return <svg>
                <path fill="#f3f3f3" d="M0 0h23v23H0z" /><path fill="#f35325" d="M1 1h10v10H1z" /><path fill="#81bc06" d="M12 1h10v10H12z" /><path fill="#05a6f0" d="M1 12h10v10H1z" /><path fill="#ffba08" d="M12 12h10v10H12z" />
            </svg>;
        default:
            return <svg>
                <path fill="#f3f3f3" d="M0 0h23v23H0z" /><path fill="#f35325" d="M1 1h10v10H1z" /><path fill="#81bc06" d="M12 1h10v10H12z" /><path fill="#05a6f0" d="M1 12h10v10H1z" /><path fill="#ffba08" d="M12 12h10v10H12z" />
            </svg>;
    }
}


export default { retornarIconeModulo, callMsGraph, retornarIconeAutenticacaoExterna, recuperarCpfAzure, recuperarOidPorEmail };